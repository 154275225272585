.column__wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

.columns-area {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  position: relative;

  &__panels {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &__pane {
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      display: flex;
      justify-content: flex-end;
      min-width: 285px;

      &--start {
        justify-content: flex-start;
      }

      &__inner {
        position: fixed;
        width: 285px;
        pointer-events: auto;
        height: 100%;
      }
    }

    &__main {
      box-sizing: border-box;
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $no-gap-breakpoint) {
        padding: 0 10px;
        max-width: 600px;
      }
    }
  }
}

$ui-header-height: 55px;

.ui__header {
  display: none;
  box-sizing: border-box;
  height: $ui-header-height;
  position: sticky;
  top: 0;
  z-index: 3;
  justify-content: space-between;
  align-items: center;

  &__logo {
    display: inline-flex;
    padding: 15px;

    .logo {
      height: $ui-header-height - 30px;
      width: auto;
    }

    .logo--wordmark {
      display: none;
    }

    @media screen and (width >= 320px) {
      .logo--wordmark {
        display: block;
      }

      .logo--icon {
        display: none;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    overflow: hidden;

    .button {
      flex: 0 0 auto;
    }

    .button-tertiary {
      flex-shrink: 1;
    }

    .icon {
      width: 22px;
      height: 22px;
    }
  }
}

.tabs-bar__wrapper {
  background: darken($ui-base-color, 8%);
  position: sticky;
  top: $ui-header-height;
  z-index: 2;
  padding-top: 0;

  @media screen and (min-width: $no-gap-breakpoint) {
    padding-top: 10px;
    top: 0;
  }
}

.react-swipeable-view-container {
  &,
  .columns-area,
  .column {
    height: 100%;
  }
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.column {
  width: 330px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  > .scrollable {
    background: $ui-base-color;
  }
}

.ui {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.column {
  overflow: hidden;
}

.column-back-button {
  box-sizing: border-box;
  width: 100%;
  background: $ui-base-color;
  border-radius: 4px 4px 0 0;
  color: $highlight-text-color;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  text-align: unset;
  padding: 15px;
  margin: 0;
  z-index: 3;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
}

.column-header__back-button {
  display: flex;
  align-items: center;
  background: $ui-base-color;
  border: 0;
  font-family: inherit;
  color: $highlight-text-color;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  flex: 0 0 auto;
  padding: 0;
  padding-inline-end: 5px;
  z-index: 3;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    padding: 0;
    padding-inline-end: 15px;
  }
}

.column-back-button__icon {
  display: inline-block;
  margin-inline-end: 5px;
}

.switch-to-advanced {
  color: $light-text-color;
  background-color: $ui-base-color;
  padding: 15px;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 13px;
  line-height: 18px;

  .switch-to-advanced__toggle {
    color: $ui-button-tertiary-color;
    font-weight: bold;
  }
}

.column-link {
  background: lighten($ui-base-color, 8%);
  color: $primary-text-color;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  padding: 13px;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  border-left: 4px solid transparent;

  &:hover,
  &:focus,
  &:active {
    background: lighten($ui-base-color, 11%);
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    border-color: $ui-button-focus-outline-color;
    border-radius: 0;
  }

  &--transparent {
    background: transparent;
    color: $ui-secondary-color;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $primary-text-color;
    }

    &.active {
      color: $highlight-text-color;
    }
  }

  &--logo {
    background: transparent;
    padding: 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }
}

.column-subheading {
  background: $ui-base-color;
  color: $dark-text-color;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
}

.column-header__wrapper {
  position: relative;
  flex: 0 0 auto;
  z-index: 1;

  &.active {
    box-shadow: 0 1px 0 rgba($highlight-text-color, 0.3);

    &::before {
      display: block;
      content: '';
      position: absolute;
      bottom: -13px;
      inset-inline-start: 0;
      inset-inline-end: 0;
      margin: 0 auto;
      width: 60%;
      pointer-events: none;
      height: 28px;
      z-index: 1;
      background: radial-gradient(
        ellipse,
        rgba($ui-highlight-color, 0.23) 0%,
        rgba($ui-highlight-color, 0) 60%
      );
    }
  }

  .announcements {
    z-index: 1;
    position: relative;
  }
}

.column-header {
  display: flex;
  font-size: 16px;
  background: $ui-base-color;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  border-radius: 4px 4px 0 0;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  outline: 0;

  & > button {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
    border: 0;
    padding: 13px;
    color: inherit;
    background: transparent;
    font: inherit;
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;

    &:focus-visible {
      outline: $ui-button-icon-focus-outline;
    }
  }

  & > .column-header__back-button {
    color: $highlight-text-color;
  }

  &.active {
    .column-header__icon {
      color: $highlight-text-color;
      text-shadow: 0 0 10px rgba($ui-highlight-color, 0.4);
    }
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.column {
  width: 330px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .wide .columns-area:not(.columns-area--mobile) & {
    flex: auto;
    min-width: 330px;
    max-width: 400px;
  }

  > .scrollable {
    background: $ui-base-color;
    border-radius: 0 0 4px 4px;
  }
}

.column-header__buttons {
  height: 48px;
  display: flex;
  margin-inline-start: 0;
}

.column-header__links {
  margin-bottom: 14px;
}

.column-header__links .text-btn {
  margin-inline-end: 10px;
}

.column-header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $ui-base-color;
  border: 0;
  color: $darker-text-color;
  cursor: pointer;
  font-size: 16px;
  padding: 0 15px;

  &:last-child {
    border-start-end-radius: 4px;
  }

  &:hover {
    color: lighten($darker-text-color, 7%);
  }

  &:focus-visible {
    outline: $ui-button-focus-outline;
  }

  &.active {
    color: $primary-text-color;
    background: lighten($ui-base-color, 8%);

    &:hover {
      color: $primary-text-color;
      background: lighten($ui-base-color, 8%);
    }
  }

  &:disabled {
    color: $dark-text-color;
    cursor: default;
  }
}

.column-header__notif-cleaning-buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  .column-header__button {
    background: transparent;
    text-align: center;
    padding: 10px 5px;
    font-size: 14px;
  }

  b {
    font-weight: bold;
  }
}

.layout-single-column .column-header__notif-cleaning-buttons {
  @media screen and (min-width: $no-gap-breakpoint) {
    b,
    i {
      margin-inline-end: 5px;
    }

    br {
      display: none;
    }

    button {
      padding: 15px 5px;
    }
  }
}

// The notifs drawer with no padding to have more space for the buttons
.column-header__collapsible-inner.nopad-drawer {
  padding: 0;
}

.column-header__collapsible {
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  color: $darker-text-color;
  transition:
    max-height 150ms ease-in-out,
    opacity 300ms linear;
  opacity: 1;
  z-index: 1;
  position: relative;

  &.collapsed {
    max-height: 0;
    opacity: 0.5;
  }

  &.animating {
    overflow-y: hidden;
  }

  hr {
    height: 0;
    background: transparent;
    border: 0;
    border-top: 1px solid lighten($ui-base-color, 12%);
    margin: 10px 0;
  }

  // notif cleaning drawer
  &.ncd {
    transition: none;

    &.collapsed {
      max-height: 0;
      opacity: 0.7;
    }
  }
}

.column-header__collapsible-inner {
  background: lighten($ui-base-color, 8%);
  padding: 15px;
}

.column-header__setting-btn {
  &:hover,
  &:focus {
    color: $darker-text-color;
    text-decoration: underline;
  }
}

.column-header__collapsible__extra + .column-header__setting-btn {
  padding-top: 5px;
}

.column-header__permission-btn {
  display: inline;
  font-weight: inherit;
  text-decoration: underline;
}

.column-header__setting-arrows {
  float: right;

  .column-header__setting-btn {
    padding: 5px;

    &:first-child {
      padding-inline-end: 7px;
    }

    &:last-child {
      padding-inline-start: 7px;
      margin-inline-start: 5px;
    }
  }
}

.column-header__title {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.column-header__issue-btn {
  color: $warning-red;

  &:hover {
    color: $error-red;
    text-decoration: underline;
  }
}

.column-settings__pillbar {
  display: flex;
  overflow: hidden;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  margin-bottom: 10px;
  align-items: stretch;
  gap: 2px;
}

.pillbar-button {
  border: 0;
  color: #fafafa;
  padding: 2px;
  margin: 0;
  font-size: inherit;
  flex: auto;
  background-color: $ui-base-color;
  transition: all 0.2s ease;
  transition-property: background-color, box-shadow;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($ui-base-color, 10%);
    }

    &.active {
      background-color: darken($ui-highlight-color, 2%);

      &:hover,
      &:focus {
        background-color: $ui-highlight-color;
      }
    }
  }
}

.limited-account-hint {
  p {
    color: $secondary-text-color;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.empty-column-indicator,
.follow_requests-unlocked_explanation {
  color: $dark-text-color;
  background: $ui-base-color;
  text-align: center;
  padding: 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  & > span {
    max-width: 500px;
  }

  a {
    color: $highlight-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.follow_requests-unlocked_explanation {
  background: darken($ui-base-color, 4%);
  contain: initial;
  flex-grow: 0;
}

.error-column {
  padding: 20px;
  background: $ui-base-color;
  border-radius: 4px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;

  &__image {
    width: 70%;
    max-width: 350px;
    margin-top: -50px;
  }

  &__message {
    text-align: center;
    color: $darker-text-color;
    font-size: 15px;
    line-height: 22px;

    h1 {
      font-size: 28px;
      line-height: 33px;
      font-weight: 700;
      margin-bottom: 15px;
      color: $primary-text-color;
    }

    p {
      max-width: 48ch;
    }

    &__actions {
      margin-top: 30px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }
}

.column-inline-form {
  padding: 7px 15px;
  padding-inline-end: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: lighten($ui-base-color, 4%);

  label {
    flex: 1 1 auto;

    input {
      width: 100%;
      margin-bottom: 6px;

      &:focus {
        outline: 0;
      }
    }
  }

  .icon-button {
    flex: 0 0 auto;
    margin: 0 5px;
  }
}

.column-settings__outer {
  background: lighten($ui-base-color, 8%);
  padding: 15px;
}

.column-settings__section {
  color: $darker-text-color;
  cursor: default;
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}

.column-settings__row--with-margin {
  margin-bottom: 15px;
}

.column-settings__hashtags {
  .column-settings__row {
    margin-bottom: 15px;
  }

  .column-select {
    &__control {
      @include search-input;

      &::placeholder {
        color: lighten($darker-text-color, 4%);
      }

      &::-moz-focus-inner {
        border: 0;
      }

      &::-moz-focus-inner,
      &:focus,
      &:active {
        outline: 0 !important;
      }

      &:focus {
        background: lighten($ui-base-color, 4%);
      }

      @media screen and (width <= 600px) {
        font-size: 16px;
      }
    }

    &__placeholder {
      color: $dark-text-color;
      padding-inline-start: 2px;
      font-size: 12px;
    }

    &__value-container {
      padding-inline-start: 6px;
    }

    &__multi-value {
      background: lighten($ui-base-color, 8%);

      &__remove {
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          background: lighten($ui-base-color, 12%);
          color: lighten($darker-text-color, 4%);
        }
      }
    }

    &__multi-value__label,
    &__input,
    &__input-container {
      color: $darker-text-color;
    }

    &__clear-indicator,
    &__dropdown-indicator {
      cursor: pointer;
      transition: none;
      color: $dark-text-color;

      &:hover,
      &:active,
      &:focus {
        color: lighten($dark-text-color, 4%);
      }
    }

    &__indicator-separator {
      background-color: lighten($ui-base-color, 8%);
    }

    &__menu {
      @include search-popout;

      padding: 0;
      background: $ui-secondary-color;
    }

    &__menu-list {
      padding: 6px;
    }

    &__option {
      color: $inverted-text-color;
      border-radius: 4px;
      font-size: 14px;

      &--is-focused,
      &--is-selected {
        background: darken($ui-secondary-color, 10%);
      }
    }
  }
}

.column-settings__row {
  .text-btn:not(.column-header__permission-btn) {
    margin-bottom: 15px;
  }
}

.notifications-permission-banner {
  padding: 30px;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    inset-inline-end: 10px;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
  }

  p {
    color: $darker-text-color;
    margin-bottom: 15px;
    text-align: center;
  }
}

.column-title {
  text-align: center;
  padding-bottom: 32px;

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $darker-text-color;
  }

  @media screen and (width >= 600px) {
    padding: 40px;
  }
}

.onboarding__footer {
  margin-top: 30px;
  color: $dark-text-color;
  text-align: center;
  font-size: 14px;

  .link-button {
    display: inline-block;
    color: inherit;
    font-size: inherit;
  }
}

.onboarding__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: $highlight-text-color;
  background: lighten($ui-base-color, 4%);
  border-radius: 8px;
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  height: 56px;
  text-decoration: none;

  svg {
    height: 1.5em;
  }

  &:hover,
  &:focus,
  &:active {
    background: lighten($ui-base-color, 8%);
  }
}

.onboarding__illustration {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  max-height: 200px;
  width: auto;
}

.onboarding__lead {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $darker-text-color;
  text-align: center;
  margin-bottom: 30px;

  strong {
    font-weight: 700;
    color: $secondary-text-color;
  }
}

.onboarding__links {
  margin-bottom: 30px;

  & > * {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.onboarding__steps {
  margin-bottom: 30px;

  &__item {
    background: lighten($ui-base-color, 4%);
    border: 0;
    border-radius: 8px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 10px;
    padding: 10px;
    padding-inline-end: 15px;
    margin-bottom: 2px;
    text-decoration: none;
    text-align: start;

    &:hover,
    &:focus,
    &:active {
      background: lighten($ui-base-color, 8%);
    }

    &__icon {
      flex: 0 0 auto;
      border-radius: 50%;
      display: none;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      color: $highlight-text-color;
      font-size: 1.2rem;

      @media screen and (width >= 600px) {
        display: flex;
      }
    }

    &__progress {
      flex: 0 0 auto;
      background: $valid-value-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 21px;
      color: $primary-text-color;

      svg {
        height: 14px;
        width: auto;
      }
    }

    &__go {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 21px;
      color: $highlight-text-color;
      font-size: 17px;

      svg {
        height: 1.5em;
        width: auto;
      }
    }

    &__description {
      flex: 1 1 auto;
      line-height: 20px;

      h6 {
        color: $highlight-text-color;
        font-weight: 500;
        font-size: 14px;
      }

      p {
        color: $darker-text-color;
        overflow: hidden;
      }
    }
  }
}

.follow-recommendations {
  background: darken($ui-base-color, 4%);
  border-radius: 8px;
  margin-bottom: 30px;

  .account:last-child {
    border-bottom: 0;
  }

  &__empty {
    text-align: center;
    color: $darker-text-color;
    font-weight: 500;
    padding: 40px;
  }
}

.tip-carousel {
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 30px;

  &:focus {
    outline: 0;
    border-color: $highlight-text-color;
  }

  .media-modal__pagination {
    margin-bottom: 0;
  }
}

.copy-paste-text {
  background: lighten($ui-base-color, 4%);
  border-radius: 8px;
  border: 1px solid lighten($ui-base-color, 8%);
  padding: 16px;
  color: $primary-text-color;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: border-color 300ms linear;
  margin-bottom: 30px;

  &:focus,
  &.focused {
    transition: none;
    outline: 0;
    border-color: $highlight-text-color;
  }

  &.copied {
    border-color: $valid-value-color;
    transition: none;
  }

  textarea {
    width: 100%;
    height: auto;
    background: transparent;
    color: inherit;
    font: inherit;
    border: 0;
    padding: 0;
    margin-bottom: 30px;
    resize: none;

    &:focus {
      outline: 0;
    }
  }
}

.onboarding__profile {
  position: relative;
  margin-bottom: 40px + 20px;

  .app-form__avatar-input {
    border: 2px solid $ui-base-color;
    position: absolute;
    inset-inline-start: -2px;
    bottom: -40px;
    z-index: 2;
  }

  .app-form__header-input {
    margin: 0 -20px;
    border-radius: 0;

    img {
      border-radius: 0;
    }
  }
}

.compose-form__highlightable {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  border-radius: 4px;
  transition: box-shadow 300ms linear;
  min-height: 0;
  position: relative;

  &.active {
    transition: none;
    box-shadow: 0 0 0 6px rgba(lighten($highlight-text-color, 8%), 0.7);
  }
}

.dismissable-banner,
.warning-banner {
  position: relative;
  margin: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid $highlight-text-color;
  background: rgba($highlight-text-color, 0.15);
  overflow: hidden;

  &__background-image {
    width: 125%;
    position: absolute;
    bottom: -25%;
    inset-inline-end: -25%;
    z-index: -1;
    opacity: 0.15;
    mix-blend-mode: luminosity;
  }

  &__message {
    flex: 1 1 auto;
    padding: 15px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: $primary-text-color;

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      color: $highlight-text-color;
      font-size: 22px;
      line-height: 33px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      &__wrapper {
        display: flex;
        margin-top: 30px;
      }

      .button {
        display: block;
        flex-grow: 1;
      }
    }

    .button-tertiary {
      background: rgba($ui-base-color, 0.15);
      backdrop-filter: blur(8px);
    }
  }

  &__action {
    float: right;
    padding: 15px 10px;

    .icon-button {
      color: $highlight-text-color;
    }
  }
}

.warning-banner {
  border: 1px solid $warning-red;
  background: rgba($warning-red, 0.15);

  &__message {
    h1 {
      color: $warning-red;
    }

    a {
      color: $primary-text-color;
    }
  }
}

.hashtag-header {
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  padding: 15px;
  font-size: 17px;
  line-height: 22px;
  color: $darker-text-color;

  strong {
    font-weight: 700;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;

    h1 {
      color: $primary-text-color;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 22px;
      line-height: 33px;
      font-weight: 700;
    }
  }

  &:focus {
    outline: 0;
    background-color: $highlight-text-color;
  }
}
