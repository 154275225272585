.compose-panel {
  width: 285px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  overflow-y: hidden;

  .hero-widget {
    box-shadow: none;

    &__text,
    &__img,
    &__img img {
      border-radius: 0;
    }

    &__text {
      padding: 15px;
      color: $secondary-text-color;

      strong {
        font-weight: 700;
        color: $primary-text-color;
      }
    }
  }

  .search__input {
    line-height: 18px;
    font-size: 16px;
    padding: 15px;
    padding-inline-end: 30px;
  }

  .search__icon .fa {
    top: 15px;
  }

  .navigation-bar {
    flex: 0 1 48px;
  }

  .compose-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 310px;
  }

  .compose-form__autosuggest-wrapper {
    overflow-y: auto;
    background-color: $white;
    border-radius: 4px 4px 0 0;
    flex: 0 1 auto;
  }

  .autosuggest-textarea__textarea {
    overflow-y: hidden;
  }
}

.navigation-panel {
  margin-top: 10px;
  margin-bottom: 10px;
  height: calc(100% - 20px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > a {
    flex: 0 0 auto;
  }

  .logo {
    height: 30px;
    width: auto;
  }
}

.navigation-panel,
.compose-panel {
  hr {
    flex: 0 0 auto;
    border: 0;
    background: transparent;
    border-top: 1px solid lighten($ui-base-color, 4%);
    margin: 10px 0;
  }

  .flex-spacer {
    background: transparent;
  }
}

.columns-area--mobile {
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  .column,
  .drawer {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .account-card {
    margin-bottom: 0;
  }

  .filter-form {
    display: flex;
    flex-wrap: wrap;
  }

  .autosuggest-textarea__textarea {
    font-size: 16px;
  }

  .search__input {
    line-height: 18px;
    font-size: 16px;
    padding: 15px;
    padding-inline-end: 30px;
  }

  .search__icon .fa {
    top: 15px;
  }

  .scrollable {
    overflow: visible;

    @supports (display: grid) {
      contain: content;
    }
  }

  @media screen and (min-width: $no-gap-breakpoint) {
    padding: 10px 0;
    padding-top: 0;
  }

  .detailed-status {
    padding: 15px;

    .media-gallery,
    .video-player,
    .audio-player {
      margin-top: 15px;
    }
  }

  .account__header__bar {
    padding: 5px 10px;
  }

  .navigation-bar,
  .compose-form {
    padding: 15px;
  }

  .compose-form .compose-form__publish .compose-form__publish-button-wrapper {
    padding-top: 15px;
  }

  .notification__report {
    padding: 15px;
    padding-inline-start: (48px + 15px * 2);
    min-height: 48px + 2px;

    &__avatar {
      inset-inline-start: 15px;
      top: 17px;
    }
  }

  .status {
    padding: 15px;
    min-height: 48px + 2px;

    .media-gallery,
    &__action-bar,
    .video-player,
    .audio-player {
      margin-top: 10px;
    }
  }

  .account {
    padding: 15px 10px;

    &__header__bio {
      margin: 0 -10px;
    }
  }

  .notification {
    &__message {
      padding-top: 15px;
    }

    .status {
      padding-top: 8px;
    }

    .account {
      padding-top: 8px;
    }
  }
}

@media screen and (min-width: $no-gap-breakpoint) {
  .react-swipeable-view-container .columns-area--mobile {
    height: calc(100% - 10px) !important;
  }

  .getting-started__wrapper {
    margin-bottom: 10px;
  }

  .search-page .search {
    display: none;
  }

  .navigation-panel__legal {
    display: none;
  }
}

@media screen and (max-width: $no-gap-breakpoint - 1px) {
  $sidebar-width: 285px;

  .columns-area__panels__main {
    width: calc(100% - $sidebar-width);
  }

  .columns-area__panels {
    min-height: calc(100vh - $ui-header-height);
  }

  .columns-area__panels__pane--navigational {
    min-width: $sidebar-width;

    .columns-area__panels__pane__inner {
      width: $sidebar-width;
    }

    .navigation-panel {
      margin: 0;
      background: $ui-base-color;
      border-inline-start: 1px solid lighten($ui-base-color, 8%);
      height: 100vh;
    }

    .navigation-panel__sign-in-banner,
    .navigation-panel__logo,
    .navigation-panel__banner,
    .getting-started__trends {
      display: none;
    }

    .column-link__icon {
      font-size: 18px;
    }
  }

  .layout-single-column .ui__header {
    display: flex;
    background: $ui-base-color;
    border-bottom: 1px solid lighten($ui-base-color, 8%);
  }

  .column-header,
  .column-back-button,
  .scrollable,
  .error-column {
    border-radius: 0 !important;
  }
}

@media screen and (max-width: $no-gap-breakpoint - 285px - 1px) {
  $sidebar-width: 55px;

  .columns-area__panels__main {
    width: calc(100% - $sidebar-width);
  }

  .columns-area__panels__pane--navigational {
    min-width: $sidebar-width;

    .columns-area__panels__pane__inner {
      width: $sidebar-width;
    }

    .column-link span {
      display: none;
    }

    .list-panel {
      display: none;
    }
  }
}

.explore__search-header {
  display: none;
}

@media screen and (max-width: $no-gap-breakpoint - 1px) {
  .columns-area__panels__pane--compositional {
    display: none;
  }

  .explore__search-header {
    display: flex;
  }
}
